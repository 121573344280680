
body, html {
  margin: 0;
  font-family: 'Montserrat', -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  
  -ms-overflow-style: none;
}


html {
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}
a:visited {
  color: inherit;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.text-bolder {
  font-weight: bolder !important;
}
.text500 {
  font-weight: 500 !important;
}
.text-transform-none{
  text-transform: none;
}
.opacity9 {
  opacity: 0.9;
}
.opacity5 {
  opacity: 0.5;
}
.letterspacing3 {
  letter-spacing: .3px !important;
}

.full-width {
  width: 100% !important;
}
.mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: none;
}
.mapboxgl-ctrl-geocoder--icon {
  display: none;
}

.imageHeader {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.imageHeader video {
  object-fit: cover;
}

.videoHeader {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.display-inline-block {
  display: inline-block !important;
}

.transition-all {
  transition: all .3s ease;
}

.transition-all-2s {
  transition: all .3s ease;
}

.zoom-in-on-hover:hover {
  transform: scale(1.1);
}

.zoom-in-on-press:active {
  transform: scale(1.2);
}

.box-shadow-on-hover:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.text-secondary {
  color: #7dccb2 !important
}

.a-active-color-secondary:visited {
  color: #80cab1 !important;
}

.a-active-color-primary:visited {
  color: rgb(6, 21, 102) !important;
}

.a-active-color-white:visited {
  color: white !important;
}

.a-active-color-grey:visited {
  color: rgba(138, 138, 138, 1) !important;
}

/** On mobile **/
@media screen and (max-width: 600px) {
  .tx-body {
    font-size: 12px !important;
  }

  .tx-body-gras {
    font-size: 12px !important;
    font-weight: bold;
  }
  
  .tx-body-petit {
    font-size: 12px !important;
    font-weight: bold;
  }
  
  .h1 {
    font-size: 19px !important;
    font-weight: bold;
  }

  .h2 {
    font-size: 17px !important;
    font-weight: bold;
  }

  .h3 {
    font-size: 16px !important;
    font-weight: bold;
  }

  .h4 {
    font-size: 12px !important;
    font-weight: bold;
  }

  .h5 {
    font-size: 11px !important;
    font-weight: bold;
  }
}

@media screen and (min-width: 600px) {

  /** On desktop  **/
  .tx-body {
    font-size: 17.5px !important;
  }
  
  .label {
    font-size: 17.5px !important;
  }
  
  .body-light {
    font-size: 16.5px !important;
    font-weight: lighter;
  }
  
  .lien {
    font-size: 16.5px !important;
  }
  
  .lien-petit {
    font-size: 15px !important;
  }
  
  .h1 {
    font-size: 32px !important;
  }
  
  .h2 {
    font-size: 25px !important;
  }
  
  .h3 {
    font-size: 23.5px !important;
  }
  
  .h4 {
    font-size: 18px !important;
  }
  
  .h5 {
    font-size: 16px !important;
  }
}
.center-column{
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-grid-column>.swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: row;
}

.swiper-button-next {
  background-image: url("../public/assets/img/icon/swiper-next-navigation.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background-image: url("../public/assets/img/icon/swiper-prev-navigation.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-pagination .swiper-pagination-bullet {
  background-color: #DEE2E5;
  width: 10px;
  height: 10px;
  border-left: 1px solid #061566;
  opacity: 1
}
.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #061566;
  border-radius: 20px;
  border: 'none'
}

/** For axeptio **/
.axeptio_mount #axeptio_main_button{
  bottom: 60px !important;
}
.bepVfn svg path:first-of-type {
  fill: #061566;
}

.react-switch-bg{
  background-color: #061566 !important;
}

.bEBISY span svg path{
  fill: #061566 ;
}


.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.eSyxGx.eSyxGx .gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa:hover{
  color:#061566 !important;
}

.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.ButtonGroup__ButtonGroupStyle-sc-1usw1pe-1.eSyxGx.eSyxGx 
.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa.gTyPIa{
  color:#061566 !important;
}
.Elements__Content-sc-xuz5b0-2 .Thumbprint__Img{
  filter: invert(46%) sepia(45%) saturate(500%) hue-rotate(160deg) brightness(40%) contrast(99%);
}