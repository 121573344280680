svg .svg-elem-1 {
    stroke-dashoffset: 883.2556762695312px;
    stroke-dasharray: 883.2556762695312px;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}

svg.active .svg-elem-1 {
    stroke-dashoffset: 0;
}

svg .svg-elem-2 {
    stroke-dashoffset: 33.546016693115234px;
    stroke-dasharray: 33.546016693115234px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
}

svg.active .svg-elem-2 {
    stroke-dashoffset: 0;
    fill: rgb(125, 204, 178);
}

svg .svg-elem-3 {
    stroke-dashoffset: 22.799964904785156px;
    stroke-dasharray: 22.799964904785156px;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
}

svg.active .svg-elem-3 {
    stroke-dashoffset: 0;
}

svg .svg-elem-4 {
    stroke-dashoffset: 22.799964904785156px;
    stroke-dasharray: 22.799964904785156px;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
}

svg.active .svg-elem-4 {
    stroke-dashoffset: 0;
}

svg .svg-elem-5 {
    stroke-dashoffset: 22.799964904785156px;
    stroke-dasharray: 22.799964904785156px;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
}

svg.active .svg-elem-5 {
    stroke-dashoffset: 0;
}

svg .svg-elem-6 {
    stroke-dashoffset: 22.799964904785156px;
    stroke-dasharray: 22.799964904785156px;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
}

svg.active .svg-elem-6 {
    stroke-dashoffset: 0;
}

svg .svg-elem-7 {
    stroke-dashoffset: 205.347412109375px;
    stroke-dasharray: 205.347412109375px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
}

svg.active .svg-elem-7 {
    stroke-dashoffset: 0;
    fill: rgb(125, 204, 178);
}

svg .svg-elem-8 {
    stroke-dashoffset: 233.89254760742188px;
    stroke-dasharray: 233.89254760742188px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
}

svg.active .svg-elem-8 {
    stroke-dashoffset: 0;
    fill: rgb(125, 204, 178);
}

svg .svg-elem-9 {
    stroke-dashoffset: 233.8753204345703px;
    stroke-dasharray: 233.8753204345703px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
}

svg.active .svg-elem-9 {
    stroke-dashoffset: 0;
    fill: rgb(125, 204, 178);
}

svg .svg-elem-10 {
    stroke-dashoffset: 212.05128479003906px;
    stroke-dasharray: 212.05128479003906px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
}

svg.active .svg-elem-10 {
    stroke-dashoffset: 0;
    fill: rgb(125, 204, 178);
}

svg .svg-elem-11 {
    stroke-dashoffset: 233.88650512695312px;
    stroke-dasharray: 233.88650512695312px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
}

svg.active .svg-elem-11 {
    stroke-dashoffset: 0;
    fill: rgb(125, 204, 178);
}

svg .svg-elem-12 {
    stroke-dashoffset: 395.385009765625px;
    stroke-dasharray: 395.385009765625px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
}

svg.active .svg-elem-12 {
    stroke-dashoffset: 0;
    fill: rgb(125, 204, 178);
}

svg .svg-elem-13 {
    stroke-dashoffset: 212.06698608398438px;
    stroke-dasharray: 212.06698608398438px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
}

svg.active .svg-elem-13 {
    stroke-dashoffset: 0;
    fill: rgb(125, 204, 178);
}

svg .svg-elem-14 {
    stroke-dashoffset: 343.6148376464844px;
    stroke-dasharray: 343.6148376464844px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
}

svg.active .svg-elem-14 {
    stroke-dashoffset: 0;
    fill: rgb(255, 255, 255);
}

svg .svg-elem-15 {
    stroke-dashoffset: 343.6148376464844px;
    stroke-dasharray: 343.6148376464844px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
}

svg.active .svg-elem-15 {
    stroke-dashoffset: 0;
    fill: rgb(7, 25, 100);
}

svg .svg-elem-16 {
    stroke-dashoffset: 109.74745178222656px;
    stroke-dasharray: 109.74745178222656px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
}

svg.active .svg-elem-16 {
    stroke-dashoffset: 0;
    fill: rgb(255, 255, 255);
}

svg .svg-elem-17 {
    stroke-dashoffset: 109.74745178222656px;
    stroke-dasharray: 109.74745178222656px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s;
}

svg.active .svg-elem-17 {
    stroke-dashoffset: 0;
    fill: rgb(7, 25, 100);
}

svg .svg-elem-18 {
    stroke-dashoffset: 12.0509033203125px;
    stroke-dasharray: 12.0509033203125px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s;
}

svg.active .svg-elem-18 {
    stroke-dashoffset: 0;
    fill: rgb(255, 255, 255);
}

svg .svg-elem-19 {
    stroke-dashoffset: 10.176517486572266px;
    stroke-dasharray: 10.176517486572266px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s;
}

svg.active .svg-elem-19 {
    stroke-dashoffset: 0;
    fill: rgb(255, 255, 255);
}

svg .svg-elem-20 {
    stroke-dashoffset: 13.328863143920898px;
    stroke-dasharray: 13.328863143920898px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.28s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.28s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s;
}

svg.active .svg-elem-20 {
    stroke-dashoffset: 0;
    fill: rgb(255, 255, 255);
}

svg .svg-elem-21 {
    stroke-dashoffset: 14.82873821258545px;
    stroke-dasharray: 14.82873821258545px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s;
}

svg.active .svg-elem-21 {
    stroke-dashoffset: 0;
    fill: rgb(255, 255, 255);
}

svg .svg-elem-22 {
    stroke-dashoffset: 233.79151916503906px;
    stroke-dasharray: 233.79151916503906px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.52s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.9000000000000004s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.52s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.9000000000000004s;
}

svg.active .svg-elem-22 {
    stroke-dashoffset: 0;
    fill: rgb(255, 255, 255);
}

svg .svg-elem-23 {
    stroke-dashoffset: 233.79151916503906px;
    stroke-dasharray: 233.79151916503906px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6399999999999997s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6399999999999997s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3s;
}

svg.active .svg-elem-23 {
    stroke-dashoffset: 0;
    fill: rgb(7, 25, 100);
}

svg .svg-elem-24 {
    stroke-dashoffset: 233.8365478515625px;
    stroke-dasharray: 233.8365478515625px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.76s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.1000000000000005s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.76s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.1000000000000005s;
}

svg.active .svg-elem-24 {
    stroke-dashoffset: 0;
    fill: rgb(255, 255, 255);
}

svg .svg-elem-25 {
    stroke-dashoffset: 233.8365478515625px;
    stroke-dasharray: 233.8365478515625px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.88s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.88s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2s;
}

svg.active .svg-elem-25 {
    stroke-dashoffset: 0;
    fill: rgb(7, 25, 100);
}

svg .svg-elem-26 {
    stroke-dashoffset: 211.9791717529297px;
    stroke-dasharray: 211.9791717529297px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.3s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.3s;
}

svg.active .svg-elem-26 {
    stroke-dashoffset: 0;
    fill: rgb(255, 255, 255);
}

svg .svg-elem-27 {
    stroke-dashoffset: 211.9791717529297px;
    stroke-dasharray: 211.9791717529297px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.12s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.4000000000000004s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.12s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.4000000000000004s;
}

svg.active .svg-elem-27 {
    stroke-dashoffset: 0;
    fill: rgb(7, 25, 100);
}

svg .svg-elem-28 {
    stroke-dashoffset: 205.34671020507812px;
    stroke-dasharray: 205.34671020507812px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2399999999999998s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.5s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2399999999999998s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.5s;
}

svg.active .svg-elem-28 {
    stroke-dashoffset: 0;
    fill: rgb(255, 255, 255);
}

svg .svg-elem-29 {
    stroke-dashoffset: 205.34671020507812px;
    stroke-dasharray: 205.34671020507812px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.36s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.6000000000000005s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.36s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.6000000000000005s;
}

svg.active .svg-elem-29 {
    stroke-dashoffset: 0;
    fill: rgb(7, 25, 100);
}
