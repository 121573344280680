.control-wrapper {
    margin-bottom: 30px;
}
.control-metadata {
    display: flex;
    flex-direction: row;
}
.text-field-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.text-field-wrapper-vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
}

@media (max-width: 800px) {
    .text-field-wrapper {
        flex-direction: column;
        align-items: start;
        justify-content: start;
    }
  }
  
  
.text-field-wrapper label {
    white-space: nowrap;
    /* padding: 0px 20px; */
    /* min-width: 120px;
    max-width: 120px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px; */
}

.text-field-wrapper-vertical label {
    white-space: nowrap;
    /* text-transform: uppercase;
    width: 100%;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    line-height: 3; */
}

.text-field-wrapper .ephatta-seed-MuiInputBase-root,
.text-field-wrapper .ephatta-seed-MuiSelect-root,
.text-field-wrapper .ephatta-seed-MuiTextField-root{
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.text-field-wrapper-vertical .ephatta-seed-MuiInputBase-root,
.text-field-wrapper-vertical .ephatta-seed-MuiSelect-root,
.text-field-wrapper-vertical .ephatta-seed-MuiTextField-root{
    border-bottom-left-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
}

.text-field-wrapper .ephatta-seed-MuiTextField-root,
.text-field-wrapper .ephatta-seed-MuiOutlinedInput-root,
.text-field-wrapper .ephatta-seed-MuiOutlinedInput-notchedOutline,
.text-field-wrapper-vertical .ephatta-seed-MuiTextField-root,
.text-field-wrapper-vertical .ephatta-seed-MuiOutlinedInput-root,
.text-field-wrapper-vertical .ephatta-seed-MuiOutlinedInput-notchedOutline {
    margin: 0;
}
.text-field-wrapper .ephatta-seed-MuiTextField-root input,
.text-field-wrapper .ephatta-seed-MuiTextField-root textarea,
.text-field-wrapper .ephatta-seed-MuiTextField-root fieldset,
.text-field-wrapper-vertical .ephatta-seed-MuiTextField-root input,
.text-field-wrapper-vertical .ephatta-seed-MuiTextField-root textarea,
.text-field-wrapper-vertical .ephatta-seed-MuiTextField-root fieldset {
    border: none;
}
.text-field-wrapper .ephatta-seed-MuiTextField-root textarea,
.text-field-wrapper-vertical .ephatta-seed-MuiTextField-root textarea {
    padding: 15px;
}
